import './App.css';
import { useEffect } from "react";
import { useStore } from '@nanostores/react'
import PeraWalletButton from './components/PeraWalletButton';
import PeraLogo from './components/PeraLogo';
import BGImg from './components/BGImg';
import {
  congrats, claimString
} from './utils/store'

function App() {
  // store value
  const congr = useStore(congrats)
  const claimStore = useStore(claimString)

  useEffect(() => {
    try {
      claimString.set(new URLSearchParams(window.location.search).get('claim-phrase').split('-').join(' '))
    } catch {}
  }, [])

  return (
    <div className="App">
      <div className='bg-img-container'>
        <div className='bg-img'>
          <BGImg></BGImg>
        </div>
      </div>
        {!!claimStore && (
          <div className='card'>
            <h2>
              <PeraLogo></PeraLogo> 
              Wallet Gateway
            </h2>
              <PeraWalletButton></PeraWalletButton>
          </div>
        )}

      {congr && (
        <>
          <div className='congrats'>
            <div className='congrats-card'>
              <div className='congrats-title'>Congratulation for claiming your NFT!<br></br>🥳🥳🥳</div>
              <button onClick={() => congrats.set(false)}>GREAT 🎉🎉🎉</button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
